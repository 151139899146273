import React from "react";

const EditPencil = ({ size = "22px" }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.375 14.6253H4.2L12.5063 6.31902L11.6813 5.49402L3.375 13.8003V14.6253ZM14.8875 5.51277L12.4875 3.11277L13.2717 2.32858C13.4864 2.11387 13.7531 2.00964 14.0719 2.01589C14.3906 2.02214 14.6563 2.13152 14.8688 2.34402L15.675 3.15027C15.8875 3.36277 15.9938 3.62527 15.9938 3.93777C15.9938 4.25027 15.8877 4.51253 15.6757 4.72457L14.8875 5.51277ZM2.81863 15.7503C2.65752 15.7503 2.52247 15.6958 2.41348 15.5869C2.30449 15.4779 2.25 15.343 2.25 15.1819V13.5764C2.25 13.5007 2.2625 13.4315 2.2875 13.369C2.3125 13.3065 2.35625 13.244 2.41875 13.1815L11.7 3.90027L14.1 6.30027L4.81875 15.5815C4.75625 15.644 4.69239 15.6878 4.62718 15.7128C4.56196 15.7378 4.49456 15.7503 4.425 15.7503H2.81863ZM12.0938 5.90652L11.6813 5.49402L12.5063 6.31902L12.0938 5.90652Z"
        fill="#333860"
      />
    </svg>
  );
};
export default EditPencil;
