import React, { useContext, useEffect, useMemo, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { useIntl } from "react-intl";
import {
  ALL_BUSINESS_UNITS,
  EMAIL_EDIT,
  SMS_EDIT,
} from "../../../providers/RBACProvider/permissionList";
import { useParams } from "react-router-dom";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { toast } from "react-toastify";
import _ from "lodash";
import ReactQuill, { displayName } from "react-quill";
import "quill-mention";
import "quill-mention/dist/quill.mention.css";

import { Icon, Typography } from "../../../components_v2";
import { SelectBox } from "../../../components/SelectBox";
import { TextArea } from "../../../components/TextArea";
import { TextBox } from "../../../components/TextBox";
import { KIND, TextButton } from "../../../components/TextButton";
import { TextBoxTag } from "../../../components/TextBoxTag/TextBoxTag";

import {
  CUSTOMER_OVERVIEW_,
  CO_TIMELINE_,
  FORWARD,
  REPLY,
  REPLY_ALL,
  CALL_DISPUTE,
  DISPUTE,
} from "../../../constants";
import {
  executeAdhocCustomerAction,
  executeCustomerAction,
} from "../../../services";
import { MaxyfiContext } from "../../../providers/MaxyfiProvider";
import { useDispatch, useSelector } from "react-redux";
import { getDocumentData } from "../../../services/documentSystem";
import { useState } from "react";
import AgentActionAlert from "../../../components_v2/AgentActionAlert";
import ActionHeader from "./ActionHeader";
import { TIMELINE_DRAWER, setDrawerState } from "../../../redux/actions";
import Replay from "../../../assets_v2/icons/Replay";
import { ParagraphMedium } from "baseui/typography";
import ReplayAll from "../../../assets_v2/icons/ReplayAll";
import Forward from "../../../assets_v2/icons/Forward";
import { yupResolver } from "@hookform/resolvers/yup";
// import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import ActionReminders from "../../../components_v2/ActionReminders";
import { PLACEMENT, StatefulPopover } from "baseui/popover";
import SelectDoc from "./SelectDoc";
import { useDropzone } from "react-dropzone";
import { addEmailAttachmentQuickActionFromDesktop } from "../../../redux/invoiceOverview/invoiceQA/action";
import {
  addEmailAttachmentFromDesktop,
  documentDrive,
  unSelectedDocumentData,
  updateEmailAttachmentFromDesktop,
} from "../../../redux/customerOverview/DMSystem/action";
import useFormat from "../../../hooks/useFormat";
import getActionReminder from "../../../utils_v2/getActionReminders";
import EmailAttachment from "../../../components/EmailAttachment/EmailAttachment";
import EmailAttachmentNew from "../../../components/EmailAttachment/EmailAttachmentNew";
const EmailType = (props) => {
  switch (props.type) {
    case REPLY:
      return (
        <div style={{ display: "flex", gap: "5px" }}>
          <Replay />
          <ParagraphMedium>Reply</ParagraphMedium>
        </div>
      );
    case REPLY_ALL:
      return (
        <div style={{ display: "flex", gap: "5px" }}>
          <ReplayAll />
          <ParagraphMedium>Reply All</ParagraphMedium>
        </div>
      );

    case FORWARD:
      return (
        <>
          {props.drawerType == "email" ? (
            <div style={{ display: "flex", gap: "5px" }}>
              <Forward />
              <ParagraphMedium>Forward</ParagraphMedium>
            </div>
          ) : (
            ""
          )}
        </>
      );
    default:
      return null;
  }
};

const NewEmailAction = ({ resource = "CUSTOMER", entity }) => {
  const quillRef = useRef(null);
  const intl = useIntl();
  const queryClient = useQueryClient();
  const { customerId } = useParams();
  const format = useFormat();
  const {
    currentOrganization,
    referenceData,
    currentDefaultFormatDetails,
    users,
    space,
  } = useContext(MaxyfiContext);

  const drawerData = useSelector((state) => state.customerOverviewDrawer);

  const customer = queryClient.getQueryData(
    `${CUSTOMER_OVERVIEW_}${customerId}`
  );
  let dispatch = useDispatch();
  const { emailAttachment } = useSelector((s) => s.customerDocumentReducer);

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      bcc: [],
      cc: [],
    },
  });

  const { emailFrom, emailBody, emailCc, drawerType, emailBcc, emailSubject } =
    useSelector((e) => e.customerOverviewReducer);
  const [isSearch, setIsSearch] = useState("");
  const [isDriveData, setIsDriveData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [selectType, setSelectType] = useState("");
  const [isAttachment, setIsAttachment] = useState(false);
  const [isInputAction, setIsInputAction] = useState({
    isCC: false,
    isBCC: false,
  });
  const onFileSelectChange = (file) => {
    setSelectedData((prevSelectedData) => {
      const isSelected = prevSelectedData.find(
        (fl) => fl.fileRef === file.fileRef
      );
      if (isSelected) {
        return prevSelectedData.filter((fl) => fl.fileRef !== file.fileRef);
      } else {
        return [...prevSelectedData, file];
      }
    });
  };

  const { isLoading, data, refetch, isFetching } = useQuery(
    [
      `document-management-system-${customerId}`,
      // {
      //   sort: sortName,
      // },
    ],
    async ({ queryKey }) => {
      // let { sort } = queryKey[1];

      if (customerId) {
        return await getDocumentData({
          organization: currentOrganization,
          customerId: customerId,
          // sort,
        });
      }
    },
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );

  // catch doc data for searching
  useEffect(() => {
    if (_.get(data, "data.docs", [])) {
      setIsDriveData(_.get(data, "data.docs", []));
    }
  }, [data]);
  useEffect(() => {
    if (
      isSearch &&
      isSearch.length > 0 &&
      data &&
      data.data &&
      data.data.docs
    ) {
      const searchResult = _.get(data, "data.docs", []).filter((item) =>
        item.file_name.toLowerCase().includes(isSearch.toLowerCase())
      );
      setIsDriveData(searchResult);
    } else {
      setIsDriveData(_.get(data, "data.docs", []));
    }
  }, [isSearch]);

  const { template, emailAttached } = useMemo(() => {
    let template = [];
    let emailAttached = [];

    emailAttachment &&
      emailAttachment.map((e) => {
        if (e.type == "TEMPLATE") {
          template.push(e.fileRef);
        } else {
          emailAttached.push(e.fileRef);
        }
      });

    return { template, emailAttached };
  }, [emailAttachment.map((e) => e.fileRef)]);
  let isAllBusinessUnit =
    space &&
    space.role_permissions &&
    space.role_permissions.includes(ALL_BUSINESS_UNITS);

  let businessUnit = isAllBusinessUnit
    ? referenceData["business_unit_list"]
    : referenceData["user_business_unit_list"];

  const contactOptions = useMemo(() => {
    let orgEmailList =
      users && Array.isArray(users)
        ? users
            .map((orgUser) => {
              if (
                orgUser &&
                orgUser.organization &&
                orgUser.organization.includes(currentOrganization)
              ) {
                if (orgUser.email) {
                  return {
                    label: orgUser.email,
                    id: orgUser.email,
                  };
                }
              }
            })
            .filter((e) => e != undefined)
        : [];

    const businessUnitReference =
      businessUnit &&
      Array.isArray(businessUnit) &&
      businessUnit.find(
        (e) => e.id === _.get(customer, "data.doc.business_unit", "")
      );
    let businessUnitEmails = [];
    if (businessUnitReference) {
      if (businessUnitReference.admin_email) {
        businessUnitEmails.push({
          id: businessUnitReference.admin_email,
          label: businessUnitReference.admin_email,
        });
      }
      if (businessUnitReference.support_email) {
        businessUnitEmails.push({
          id: businessUnitReference.support_email,
          label: businessUnitReference.support_email,
        });
      }
    }

    const contacts = _.get(customer, "data.doc.contacts", []).reduce(
      (prev, curr) => {
        let { phone, landline, email = [], address, ...rest } = curr;
        let nestedContacts = email
          .map(({ status, email_status, ...nRest }) => {
            let designation =
              referenceData && referenceData["recipient_type"]
                ? referenceData["recipient_type"].find(
                    (rf) => rf?.id === curr?.designation
                  )
                : "";

            return {
              label: `${nRest?.value}`,
              id: nRest?._id,
              recipient_id: curr?._id,
              email_status,
              status,
              designation,
            };
          })
          .filter(
            (e) =>
              (e.status === "VALID" || e.status === "LEAD") &&
              e.email_status === "VALID"
          );

        return [...prev, ...nestedContacts, ...businessUnitEmails];
      },
      []
    );

    const { data } = drawerData;

    if (data) {
      if (data?.emailType === FORWARD) {
        setSelectType("Forward");
        setValue(
          "subject",
          _.get(data, "type.call_outcome", "") === CALL_DISPUTE ||
            _.get(data, "type.type", "") === DISPUTE ||
            _.get(data, "type.outcome", "") === CALL_DISPUTE ||
            _.get(data, "type.visit_outcome", "") === CALL_DISPUTE
            ? "Fwd: " + _.get(data, "type.disputeSubject", "")
            : emailSubject
        );
        setValue(
          "content",
          _.get(data, "type.call_outcome", "") === CALL_DISPUTE ||
            _.get(data, "type.type", "") === DISPUTE ||
            _.get(data, "type.outcome", "") === CALL_DISPUTE ||
            _.get(data, "type.visit_outcome", "") === CALL_DISPUTE
            ? _.get(data, "type.disputeBody", "")
            : drawerType !== "email" && emailBody
        );
      } else if (data?.emailType == REPLY) {
        setSelectType("Replay");
        let findValueOfemail = emailFrom?.map((e) => {
          return {
            label: e,
            id: e,
          };
        });
        setValue("recipients", findValueOfemail ? findValueOfemail : []);
        setValue("subject", "Re: " + emailSubject);
      } else if (data?.emailType == REPLY_ALL) {
        setSelectType("Replay All");
        let findValueOfemail = emailFrom?.map((e) => {
          return {
            label: e,
            id: e,
          };
        });
        setValue("recipients", findValueOfemail ? findValueOfemail : []);
        if (emailBcc) {
          setIsInputAction({ ...isInputAction, isBCC: !isInputAction.isBCC });
          setValue("bcc", emailBcc);
        }
        if (emailCc) {
          setIsInputAction({ ...isInputAction, isCC: !isInputAction.isCC });
          setValue("cc", emailCc);
        }
        // setValue("bcc", emailBcc);
        // setValue("cc", emailCc);
        setValue("subject", "Re: " + emailSubject);
      }
    }

    return [...contacts, ...orgEmailList];
  }, [customer]);

  const setDrawer = (type, data) => {
    if (type) {
      dispatch(setDrawerState({ active: true, type, data }));
    }
  };

  const executeActionMutation = useMutation(
    (formValues) =>
      !drawerData || !drawerData.data || !drawerData.data.isWorkflow
        ? executeAdhocCustomerAction({
            ...formValues,
            id: customerId,
            organization: currentOrganization,
            entity: resource,
          })
        : executeCustomerAction({
            ...formValues,
            id: customerId,
            organization: currentOrganization,
            entity: resource,
          }),
    {
      onError: (error, variables, context) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = "";

          if (typeof data.error === "object") {
            errorData = Object.keys(data.error).map(
              (e) => `${e}: ${data.error[e]}`
            );
            errorData = errorData.toString();
          }

          toast.error(errorData);
        }
      },
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries({
          queryKey: [`${CUSTOMER_OVERVIEW_}${customerId}`],
        });
        queryClient.invalidateQueries({
          queryKey: [`${CO_TIMELINE_}${customerId}`],
        });
        reset();
        setDrawer(TIMELINE_DRAWER);
      },
    }
  );

  let Block = ReactQuill.Quill.import("blots/block");

  Block.tagName = "DIV";
  ReactQuill.Quill.register(Block, true);

  let Embed = ReactQuill.Quill.import("blots/embed");
  class MxTable extends Embed {
    static create(paramValue) {
      let node = super.create();
      node.innerHTML = paramValue;
      return node;
    }

    static value(node) {
      return node.innerHTML;
    }
  }

  MxTable.blotName = "table";
  MxTable.tagName = "mx-table";
  ReactQuill.Quill.register(MxTable);

  const onSubmit = async (data) => {
    let toData = data.recipients.map((e) => {
      return {
        value: e.label,
      };
    });

    let { from, to, is_hold_workflow, ...rest } = data;

    let values = {
      ...rest,
      action_type: "email",
      recipients: toData,
      cc: data.cc,
      bcc: data.bcc,
      attachments: emailAttached,
      ...getActionReminder(data, {
        is_consent_enabled: false,
        is_calendar: false,
        is_followup: false,
        is_confirmation: false,
        is_hold: false,
        is_comment: false,
      }),
      content: data.content
        ? data.content +
          (_.get(data, "emailType", "") === "FORWARD" &&
          _.get(data, "type.type", "") === "dispute"
            ? emailBody
            : "")
        : emailBody,
    };

    await executeActionMutation.mutateAsync(values);
  };
  const fileSize = 10000000;
  function uploadLogoValidation(file) {
    if (file.size > fileSize) {
      return {
        code: "file is too-large",
        message: `File Size is Largen than 1MB`,
      };
    }
  }

  const { acceptedFiles, getRootProps, getInputProps, open, fileRejections } =
    useDropzone({
      accept: {
        "image/jpg": [".jpg", ".png"],
        "text/*": [".pdf", ".csv"],
      },
      validator: uploadLogoValidation,

      onDrop: (acceptedFiles) => {
        let arrObj = acceptedFiles.map((fileDoc) => {
          let { path, type } = fileDoc;

          let splitType = type.split("/");

          return {
            name: path,
            type: splitType[1],
            mimeType: type,
            fileDoc,
          };
        });
        if (entity === "invoices") {
          dispatch(addEmailAttachmentQuickActionFromDesktop(arrObj));
        } else {
          dispatch(addEmailAttachmentFromDesktop(arrObj));
        }
      },
    });
  const recipients = _.get(drawerData, "data.type.to", [])
    .map((item) => `${_.get(item, "name", "")} <${_.get(item, "value", "")}>`)
    .join(", ");
  const formatBodyText = (htmlText) => {
    // Replace <br> and <br/> with newline characters
    let textWithNewlines = htmlText.replace(/<br\s*\/?>/gi, "\n");
    // Strip all other HTML tags
    textWithNewlines = textWithNewlines.replace(/<[^>]+>/g, "");
    return textWithNewlines;
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ width: "600px", height: "100%", overflow: "scroll" }}
      onKeyDown={(e) => {
        if (e.key === "Enter") e.preventDefault();
      }}
    >
      <EmailType type={drawerData?.data?.emailType} drawerType={drawerType} />
      <ActionHeader name="Email" icon="email" />

      <AgentActionAlert />

      <div style={{ position: "relative" }}>
        <Controller
          name="recipients"
          control={control}
          rules={{ required: "Required" }}
          render={({ field }) => (
            <SelectBox
              size="mini"
              {...field}
              name={field.name}
              requiredAstric={true}
              clearable={false}
              creatable
              error={errors[field.name] && errors[field.name].message}
              label={intl.formatMessage({
                id: "to",
              })}
              placeholder={intl.formatMessage({
                id: "to",
              })}
              multi
              onChange={(e) => {
                field.onChange(e.value);
              }}
              validation={true}
              value={field.value}
              validationFunc={(value) => {
                let rg = new RegExp(
                  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$/
                );
                return rg.test(value);
              }}
              setError={() =>
                setError(field.name, {
                  type: String,
                  message: "Invalid Email ID",
                })
              }
              clearErrors={() => {
                clearErrors(field.name, {
                  message: "",
                });
              }}
              options={contactOptions}
            />
          )}
        />
        <div className="email_forward_action_wraper">
          {!isInputAction.isCC && (
            <Typography
              subType="small"
              onClick={() =>
                setIsInputAction({
                  ...isInputAction,
                  isCC: !isInputAction.isCC,
                })
              }
            >
              CC
            </Typography>
          )}
          {!isInputAction.isBCC && (
            <Typography
              subType="small"
              onClick={() =>
                setIsInputAction({
                  ...isInputAction,
                  isBCC: !isInputAction.isBCC,
                })
              }
            >
              BCC
            </Typography>
          )}
        </div>
      </div>

      {isInputAction.isCC && (
        <Controller
          name="cc"
          control={control}
          render={({ field }) => (
            <>
              <TextBoxTag
                {...field}
                name={field.name}
                size="mini"
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "cc1",
                })}
                placeholder={intl.formatMessage({
                  id: "cc",
                })}
                value={field.value}
                validation={true}
                caption={`${`${`Business Email `}${
                  currentDefaultFormatDetails?.email?.value
                }${" will be kept in CC. "}${`You can review the Business Email in Organization Settings - Contact Details`}`}`}
                validationFunc={(value) => {
                  let rg = new RegExp(
                    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$/
                  );
                  return rg.test(value);
                }}
                setError={() =>
                  setError(field.name, {
                    type: String,
                    message: "Invalid Email ID",
                  })
                }
                clearErrors={() => {
                  clearErrors(field.name, {
                    message: "",
                  });
                }}
              />
            </>
          )}
        />
      )}

      {isInputAction.isBCC && (
        <Controller
          defaultValues={[]}
          name="bcc"
          control={control}
          render={({ field }) => (
            <>
              <TextBoxTag
                // disabled={disableContent}
                {...field}
                name={field.name}
                size="mini"
                error={errors[field.name] && errors[field.name].message}
                label={intl.formatMessage({
                  id: "bcc1",
                })}
                placeholder={intl.formatMessage({
                  id: "bcc",
                })}
                value={field.value}
                validation={true}
                validationFunc={(value) => {
                  let rg = new RegExp(
                    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$/
                  );
                  return rg.test(value);
                }}
                setError={() =>
                  setError(field.name, {
                    type: String,
                    message: "Email is Not Valid",
                  })
                }
                clearErrors={() => {
                  clearErrors(field.name, {
                    message: "",
                  });
                }}
              />
            </>
          )}
        />
      )}

      <Controller
        name="subject"
        control={control}
        rules={{ required: "Required" }}
        render={({ field }) => (
          <TextBox
            {...field}
            name={field.name}
            size="mini"
            error={errors[field.name] && errors[field.name].message}
            label={intl.formatMessage({
              id: "subject",
            })}
            placeholder={intl.formatMessage({
              id: "subject",
            })}
            value={field.value}
            disabled={space && space?.role_permissions?.includes(SMS_EDIT)}
          />
        )}
      />

      {(drawerType || _.get(drawerData, "data.emailType", "")) && (
        <div className="cp-quill --cp-cnt-quill">
          <Controller
            name="content"
            control={control}
            render={({ field }) => (
              <ReactQuill
                ref={quillRef}
                theme="snow"
                value={field.value}
                onChange={(content) => {
                  field.onChange(content);
                }}
                // modules={modal}
                placeholder={"Write something awesome..."}
                formats={[
                  "header",
                  "font",
                  "background",
                  "color",
                  "code",
                  "size",
                  "bold",
                  "italic",
                  "underline",
                  "strike",
                  "blockquote",
                  "list",
                  "bullet",
                  "indent",
                  "script",
                  "align",
                  "direction",
                  "link",
                  "image",
                  "code-block",
                  "table",
                ]}
                style={{ height: "fit-content" }}
              />
            )}
          />
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          paddingTop: "10px",
        }}
      >
        <div style={{ display: "flex" }}>
          <div
            style={
              !isAttachment
                ? {
                    display: "flex",
                    overflow: "hidden",
                    height: "35px",
                    width: "495px",
                    flexWrap: "wrap",
                    gap: "10px",
                  }
                : { display: "flex", flexWrap: "wrap", gap: "10px" }
            }
          >
            <StatefulPopover
              placement={PLACEMENT.top}
              content={(fDProps) => (
                <div className="cp_email_attachement">
                  {/* [{filename:"From Desktop",}] */}
                  <Typography
                    $style={{}}
                    onClick={() => {
                      open();
                      fDProps.close();
                    }}
                  >
                    From Desktop
                  </Typography>
                  <StatefulPopover
                    overrides={{
                      Body: {
                        style: ({ $theme }) => ({
                          borderRadius: "5px",
                          overflow: "hidden",
                          width: "351px",
                          background: "#ffffff",
                        }),
                      },
                      Inner: {
                        style: ({ $theme }) => ({
                          backgroundColor: "#ffffff",
                        }),
                      },
                    }}
                    placement={PLACEMENT.right}
                    content={({ close }) => (
                      <div style={{ padding: "10px" }}>
                        <TextBox
                          size="mini"
                          label={intl.formatMessage({
                            id: "search",
                          })}
                          placeholder={intl.formatMessage({
                            id: "search",
                          })}
                          value={isSearch}
                          onChange={(e) => {
                            setIsSearch(e.target.value);
                          }}
                        />
                        <div style={{ height: "200px", overflow: "scroll" }}>
                          {isDriveData.map((file, i) => {
                            let isSelected = selectedData.find((fl) => {
                              return fl.fileRef === file._id;
                            });
                            return (
                              <SelectDoc
                                res={file}
                                // deleteMethod={deleteDocumentData}
                                resourceId={customerId}
                                isSelected={isSelected ? true : false}
                                onFileSelectChange={onFileSelectChange}
                                entity={entity}
                                // need to change
                                // type={type}
                              />
                            );
                          })}
                        </div>
                        <div className="co-drawer-actions">
                          <TextButton
                            kind="tertiary"
                            size="mini"
                            type="button"
                            onClick={() => {
                              close();
                              setSelectedData([]);
                            }}
                          >
                            Cancel
                          </TextButton>
                          <TextButton
                            size="mini"
                            type="button"
                            onClick={() => {
                              dispatch(documentDrive(selectedData));
                              close();
                              fDProps.close();
                              setSelectedData([]);
                            }}
                          >
                            Add
                          </TextButton>
                        </div>
                      </div>
                    )}
                    returnFocus
                    autoFocus
                  >
                    <Typography>Consumer Drive</Typography>
                  </StatefulPopover>
                </div>
              )}
              returnFocus
              autoFocus
            >
              <div>
                <TextButton type="button" size="mini" kind={KIND.tertiary}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "#516beb",
                    }}
                  >
                    <Icon icon="plus" size={18} color="#516beb" /> Attachments
                  </div>
                </TextButton>
              </div>
            </StatefulPopover>
            {emailAttachment.length > 0 && (
              <>
                <>
                  {emailAttachment?.map((attachment, index) => {
                    let { tempId } = attachment;
                    return (
                      <EmailAttachmentNew
                        key={tempId}
                        {...attachment}
                        id={customerId}
                        currentOrganization={currentOrganization}
                        entity={entity}
                        removeAttchFile={unSelectedDocumentData}
                        updatingDeskUpload={updateEmailAttachmentFromDesktop}
                        recipientId={
                          recipients && recipients[0] && recipients[0].id
                        }
                        // templateId={template_id && template_id[0] && template_id[0].id}
                        paymentId={
                          drawerData &&
                          drawerData.data &&
                          drawerData.data.payment_id
                        }
                      />
                    );
                  })}
                </>
              </>
            )}
          </div>

          {emailAttachment.length >= 3 && !isAttachment ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                border: "1px solid #cdced9",
                borderRadius: "5px",
                height: "29px",
                padding: "0px 8px",
                cursor: "pointer",
              }}
              onClick={() => setIsAttachment(true)}
            >
              +{emailAttachment.length - 2}
            </div>
          ) : (
            <></>
          )}
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <ActionReminders
            watch={watch}
            control={control}
            errors={errors}
            is_comment={false}
            setValue={setValue}
            is_calendar={false}
            is_incrementor={true}
            is_hold={false}
            is_followup={false}
            is_confirmation={false}
            is_valid_from_contact={false}
          />
          <div className="co-drawer-actions" style={{ paddingRight: "10px" }}>
            <TextButton
              kind="tertiary"
              size="mini"
              type="button"
              onClick={() => {
                setDrawer(TIMELINE_DRAWER);
              }}
              disabled={executeActionMutation.isLoading}
            >
              Close
            </TextButton>
            <TextButton
              size="mini"
              disabled={executeActionMutation.isLoading}
              isLoading={executeActionMutation.isLoading}
            >
              Send
            </TextButton>
          </div>
        </div>
      </div>
      {_.get(drawerData, "data.emailType", "") &&
        _.get(drawerData, "data.type.call_outcome", "") !== "RECORD_DISPUTE" &&
        _.get(drawerData, "data.type.dispute_status", "") !== "OPEN" && (
          <div className="forward-email-content-main">
            <div style={{ textAlign: "center", paddingBottom: "10px" }}>
              <Typography type="p" className="text-secondary" subType="regular">
                - - - - - - - - - - {selectType} Message - - - - - - - - - -
              </Typography>
            </div>
            <div
              className="sub-forward-content-container"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                border: "1px solid #ccc",
                borderRadius: "5px",
                padding: "10px",
                minHeight: "100px",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  type="p"
                  className="text-secondary"
                  subType="regular"
                  style={{ maxWidth: "405px" }}
                >
                  To: {recipients}
                </Typography>
                <Typography
                  type="p"
                  className="text-secondary"
                  subType="regular"
                >
                  {_.get(drawerData, "data.subData.executed_on", null)
                    ? format.dateTime({
                        value: _.get(
                          drawerData,
                          "data.subData.executed_on",
                          null
                        ),
                        customCase: "customDateFormat",
                      })
                    : ""}
                </Typography>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "2px" }}
              >
                <Typography
                  type="p"
                  className="text-secondary"
                  subType="regular"
                >
                  Sub:
                </Typography>
                <Typography type="h4">
                  {_.get(drawerData, "data.type.subject", "")}
                </Typography>
              </div>

              <Typography type="p" className="regular" subType="regular">
                {_.get(drawerData, "data.type.body", "") ? (
                  <iframe
                    style={{
                      height: "100%",
                      width: "100%",
                      padding: "10px 0px 10px 10px",
                    }}
                    srcDoc={emailBody}
                    // src="demo_iframe_srcdoc.htm"
                  ></iframe>
                ) : (
                  ""
                )}
              </Typography>
            </div>
          </div>
        )}
      {/* <div style={{ flex: "1" }}>
        {(drawerType || _.get(drawerData, "data.emailType", "")) && (
          <iframe
            style={{
              height: "100%",
              width: "100%",
              padding: "10px 0px 10px 10px",
            }}
            className="scroll"
            srcDoc={emailBody}
            // src="demo_iframe_srcdoc.htm"
          ></iframe>
        )}
      </div> */}
    </form>
  );
};

export default NewEmailAction;
