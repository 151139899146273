import { useMutation, useQueryClient } from "react-query";
import axiosWithAuth from "../providers/AxiosInterceptor";
import { download } from "../utils/downloadFile";
import { BUSINESS_UNIT_MAINTENANCE, COMMISSION_SLAB } from "../constants";
import { toast } from "react-toastify";

const HOST = process.env.REACT_APP_HOST;
const APP_CODE = process.env.REACT_APP_APP_CODE;

export const getOrganizationData = async ({ organization }) => {
  return await axiosWithAuth.get(`${HOST}organization/${organization}`, {
    headers: {
      appCode: APP_CODE,
    },
  });
};

export const syncOrganization = async ({ organization, id }) => {
  return await axiosWithAuth.post(
    `${HOST}organization/${organization}/sync`,
    { sync_organization_id: id },
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const getOrganizationDashboard = async ({
  organization,
  id,
  entity,
  filters = {},
}) => {
  return await axiosWithAuth.get(
    `${HOST}organization/${organization}/dashboard/${entity}`,
    {
      params: {
        ...filters,
      },
    }
  );
};

export const getCustomerAnalysisDashboard = async ({
  organization,
  id,
  entity,
  filters = {},
}) => {
  return await axiosWithAuth.get(
    `${HOST}organization/${organization}/customer-interaction-dashboard/${entity}`,
    {
      params: {
        ...filters,
      },
    }
  );
};

export const getRevenueDashboard = async ({
  organization,
  id,
  entity,
  filters = {},
}) => {
  return await axiosWithAuth.get(
    `${HOST}organization/${organization}/revenue-dashboard/${entity}`,
    {
      params: {
        ...filters,
      },
    }
  );
};

export const getSMSAnalyticsDashboard = async ({
  organization,
  entity,
  filters = {},
}) => {
  return await axiosWithAuth.get(
    `${HOST}organization/${organization}/sms-deliverability/${entity}`,
    {
      params: {
        ...filters,
      },
    }
  );
};

export const getEmailAnalyticsDashboard = async ({
  organization,
  entity,
  filters = {},
}) => {
  return await axiosWithAuth.get(
    `${HOST}organization/${organization}/email-deliverability/${entity}`,
    {
      params: {
        ...filters,
      },
    }
  );
};

export const getCommandCenterDashboard = async ({
  organization,
  section,
  filters = {},
}) => {
  return await axiosWithAuth.get(
    `${HOST}organization/${organization}/command-center-dashboard/${section}`,
    {
      params: {
        ...filters,
      },
    }
  );
};

export const downloadAging = async ({ organization = null, templateType }) => {
  return await axiosWithAuth
    .get(`${HOST}organization/${organization}/ageing-csv-summery`, {
      headers: {
        appCode: APP_CODE,
      },
    })
    .then((blob) => {
      download({ fileName: "aging.csv", fileData: blob.data });
    });
};
export const stairstep = async ({
  organization = null,
  templateType,
  filters,
}) => {
  return await axiosWithAuth
    .get(`${HOST}organization/${organization}/stair-step-analysis-csv`, {
      headers: {
        appCode: APP_CODE,
      },
      params: {
        ...filters,
      },
    })
    .then((blob) => {
      download({ fileName: "StairStep.csv", fileData: blob.data });
    });
};

export const getCommissionSlab = async ({ organization }) => {
  return await axiosWithAuth.get(`${HOST}organization/${organization}/slab`, {
    headers: {
      appCode: APP_CODE,
    },
  });
};

export const orgSlabFn = async ({ organization, ...rest }) => {
  return await axiosWithAuth.post(
    `${HOST}organization/${organization}/slab`,
    rest,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

// need to add refetch data

export const useOrgSlab = (props) => {
  const client = useQueryClient();
  return useMutation(orgSlabFn, {
    onSuccess: (data) => {
      client.invalidateQueries(`${COMMISSION_SLAB}`);
    },
    onError: (err) => { },
  });
};

export const orgDiscountSlabFn = async ({ organization, ...rest }) => {
  return await axiosWithAuth.post(
    `${HOST}organization/${organization}/discount-slab`,
    rest,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const getDiscountSlabFn = async ({ organization, ...rest }) => {
  return await axiosWithAuth.get(
    `${HOST}organization/${organization}/discount-slab`,
    rest,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

// need to add refetch data

export const useOrgDiscountSlab = (props) => {
  const client = useQueryClient();
  return useMutation(orgDiscountSlabFn, {
    onSuccess: (data) => {
      client.invalidateQueries(`${COMMISSION_SLAB}`);
    },
    onError: (err) => { },
  });
};

export const businessUnitLogo = async ({ organization, formData }) => {
  return await axiosWithAuth.post(
    `${HOST}organization/${organization}/business-unit/logo`,
    formData,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const orgBusinessUnitFn = async ({ organization, ...rest }) => {
  return await axiosWithAuth.post(
    `${HOST}organization/${organization}/business-unit`,
    rest,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const useOrgBusinessUnit = (props) => {
  const client = useQueryClient();
  return useMutation(orgBusinessUnitFn, {
    onSuccess: (data) => {
      client.invalidateQueries(`${BUSINESS_UNIT_MAINTENANCE}`);
    },
    onError: (err) => {
      if (err.response) {
        let { data } = err.response;
        toast.error(data.message);
      }
    },
  });
};

export const updateOrgBusinessUnitFn = async ({
  organization,
  businessUnitId,
  ...rest
}) => {
  return await axiosWithAuth.put(
    `${HOST}organization/${organization}/business-unit/${businessUnitId}`,
    rest,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const UpdateUseOrgBusinessUnit = (props) => {
  const client = useQueryClient();
  return useMutation(updateOrgBusinessUnitFn, {
    onSuccess: (data) => {
      client.invalidateQueries(`${BUSINESS_UNIT_MAINTENANCE}`);
    },
    onError: (err) => {
      if (err.response) {
        let { data } = err.response;
        toast.error(data.message);
      }
    },
  });
};

export const getBusinessUnitData = async ({
  pageIndex = 1,
  sortBy,
  pageSize,
  filters = {},
  organization = null,
  customerFilter = {},
}) => {
  return await axiosWithAuth.post(
    `${HOST}organization/${organization}/get-business-unit`,
    customerFilter,
    {
      params: {
        page: pageIndex + 1,
        sort: sortBy,
        page_size: pageSize,
        ...filters,
      },

      // headers: {
      //   appCode: APP_CODE,
      // },
    }
  );
};

export const getBusinessUnit = async ({ organization, businessUnitId }) => {
  return await axiosWithAuth.get(
    `${HOST}organization/${organization}/business-unit/${businessUnitId}`,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const deleteBusinessUnitFn = async ({
  businessUnitId,
  organization,
}) => {
  return await axiosWithAuth.delete(
    `${HOST}organization/${organization}/business-unit/${businessUnitId}`,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

export const useDeleteBusinessUnit = (props) => {
  const client = useQueryClient();
  return useMutation(deleteBusinessUnitFn, {
    onSuccess: (data) => {
      client.invalidateQueries([`${BUSINESS_UNIT_MAINTENANCE}`], {
        refetchActive: true,
        refetchInactive: true,
      });
      client.refetchQueries([`${BUSINESS_UNIT_MAINTENANCE}`], { active: true });
    },
    onError: (err) => { },
  });
};

export const disableBusinessUnitItem = async ({
  businessUnitId,
  organization,
}) => {
  return await axiosWithAuth.patch(
    `${HOST}organization/${organization}/business-unit/${businessUnitId}`,
    {
      headers: {
        appCode: APP_CODE,
      },
    }
  );
};

// {{BS_API}}organization/{{ORGANIZATION}}business-unit/644a2b3f12eda36c126c29be
