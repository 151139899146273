import React, { useContext, useEffect } from "react";
import TitleHead from "../../../../../components_v2/TitleHead";
import { SelectBox } from "../../../../../components/SelectBox";
import { TextBox } from "../../../../../components/TextBox";
import { MaxyfiContext } from "../../../../../providers/MaxyfiProvider";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import { manualGroupingCat, manualGroupingLink } from "../../../../../services";
import useFormat from "../../../../../hooks/useFormat";
import ManualGroupingCard from "./ManualGroupingCard";
import { KIND, TextButton } from "../../../../../components/TextButton";
import { useDispatch } from "react-redux";
import { setDrawerState } from "../../../../../redux/actions";
import noRecord from "../../../../../assets/img/commandcenter.png";
import { Icon, Typography } from "../../../../../components_v2";
import queryClient from "../../../../../providers/queryClient";
import { CUSTOMER_OVERVIEW_ } from "../../../../../constants";
import Loader from "../../../../../components/Loader";

const ManualGrouping = () => {
  const { customerId } = useParams();
  let dispatch = useDispatch();
  const { referenceData, currentOrganization } = useContext(MaxyfiContext);
  const [isCategory, setIsCategory] = React.useState([]);
  const [isPreview, setIsPreview] = React.useState("");
  const [isSuccessData, setIsSuccessData] = React.useState({
    isValue: [],
    isGroup: [],
  });
  const [isGrouping, setIsGrouping] = React.useState([]);
  const format = useFormat();

  const closeDrawer = () => {
    dispatch(setDrawerState({ active: false }));
  };

  const addToGrouping = useMutation(
    (workflowData) =>
      manualGroupingLink({
        groupId: isGrouping,
        customerId: customerId,
        organization: currentOrganization,
      }),
    {
      onError: (error) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries(`${CUSTOMER_OVERVIEW_}${customerId}`);
        closeDrawer();
      },
    }
  );

  const useManualGrouping = useMutation(
    (workflowData) =>
      manualGroupingCat({
        ...workflowData,
        customerId: customerId,
        organization: currentOrganization,
      }),
    {
      onError: (error) => {
        if (error.response) {
          let { data } = error.response;
          let errorData = data.message;
          toast.error(errorData);
        }
      },
      onSuccess: (data) => {
        setIsSuccessData({
          isValue: data?.data?.doc?.value,
          isGroup: data?.data?.doc?.data,
        });
      },
    }
  );

  useEffect(() => {
    if (isSuccessData?.isValue?.length) {
      if (isCategory?.[0]?.id === "ADDRESS") {
        let previewValue = isSuccessData?.isValue?.map((e) =>
          Object.values(e).toString()
        );
        setIsPreview(previewValue);
      } else if (isCategory?.[0]?.id === "DOB") {
        let setDate = isSuccessData?.isValue?.map((e) =>
          format.date({ value: e })
        );
        setIsPreview(setDate);
      } else {
        setIsPreview(isSuccessData?.isValue);
      }
    } else {
      setIsSuccessData({ ...isSuccessData, isValue: [] });
      setIsPreview("");
    }
  }, [useManualGrouping.isLoading, useManualGrouping.isSuccess]);

  return (
    <div style={{ width: "540px" }}>
      <TitleHead title="Add Consumer for Grouping" icon="manual_group" />

      <div className="mg_textbox_container">
        <div style={{ width: "250px" }}>
          <SelectBox
            size={"mini"}
            value={isCategory}
            options={referenceData["manual_grouping_categories"]}
            onChange={async (params) => {
              setIsCategory(params.value);
              await useManualGrouping.mutateAsync({
                category: params?.value?.[0]?.id,
              });
            }}
            clearable={false}
            startEnhancer={<Icon icon="search" />}
            label="Category"
          />
        </div>
        <TextBox size={"mini"} value={isPreview.toString()} />
      </div>

      {isSuccessData?.isGroup?.map((e) => {
        return (
          <ManualGroupingCard
            {...e}
            isGrouping={isGrouping}
            setIsGrouping={setIsGrouping}
            useManualGrouping={useManualGrouping}
          />
        );
      })}

      {isSuccessData?.isGroup?.length > 0 ? (
        <div style={{ textAlign: "end", marginTop: "10px" }}>
          <TextButton
            size="mini"
            kind={KIND.tertiary}
            onClick={() => closeDrawer()}
          >
            Cancel
          </TextButton>
          <TextButton
            size="mini"
            onClick={async () => {
              await addToGrouping.mutateAsync();
            }}
            disabled={isGrouping?.length > 0 ? false : true}
          >
            Add Grouping
          </TextButton>
        </div>
      ) : useManualGrouping.isLoading ? (
        <Loader />
      ) : isCategory &&
        isCategory.length > 0 &&
        isSuccessData?.isGroup?.length === 0 ? (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "400px",
              flexDirection: "column",
            }}
          >
            <Typography
              style={{ marginTop: "40px", color: "#ADADAD" }}
              type="h3"
            >
              No Records Found
            </Typography>
          </div>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "400px",
            flexDirection: "column",
          }}
        >
          <img src={noRecord} width={"30%"} />
          <Typography style={{ marginTop: "40px", color: "#ADADAD" }}>
            Choose required category to see records ...
          </Typography>
        </div>
      )}
    </div>
  );
};

export default ManualGrouping;
